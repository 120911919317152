import { Component, OnInit } from '@angular/core';
import {SeoService} from "../../services/seo.service";

@Component({
  selector: 'app-kontakt',
  templateUrl: './kontakt.component.html',
  styleUrls: ['./kontakt.component.scss']
})
export class KontaktComponent implements OnInit {

  constructor(private seoService: SeoService) { }

  ngOnInit(): void {
    this.seoService.updateTitle('Kontakt');
    this.seoService.updateDescription('');
    this.seoService.updateKeywords('');
  }

}

import {Inject, Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {DOCUMENT} from "@angular/common";

@Injectable({
  providedIn: 'root',
})

export class StyleService {
  theme = '';
  motion = '';
  typo = '';
  view = ''
  renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2, @Inject(DOCUMENT) private document: Document) {
    this.renderer = rendererFactory.createRenderer(null, null); // to use renderer in service
  }

  initSettings(theme: string, motion: string, typo: string ): void {
    this.theme = theme;
    this.motion = motion;
    this.typo = typo;
    const _theme = this.getTheme();
      this.setTheme(_theme, false);
    const _motion = this.getMotion();
      this.setMotion(_motion, false)
    const _typo = this.getTypo();
      this.setTypo(_typo, false);
  }

  getView(): string {
    return this.view;
  }
  setView(view: string): void {
    if (this.view !== view) {
      this.view = view;
    } else {
      this.view = 'main';
    }
    window.scrollTo(0, 0);
  }

  getTheme(): string {
    if (localStorage.getItem("theme")) {
      this.theme = localStorage.getItem('theme') as any;
    }
    return this.theme;
  }
  setTheme(theme: string, store:boolean = true): void {
    this.theme = theme;
    if (store) {
      localStorage.setItem('theme', theme);
    }
    switch(theme) {
      case 'light': {
        this.renderer.addClass(this.document.body, 'light');
        this.renderer.removeClass(this.document.body, 'dark');
        break;
      }
      default: {
        this.renderer.addClass(this.document.body, 'dark');
        this.renderer.removeClass(this.document.body, 'light');
        break;
      }
    }
  }

  getMotion(): string {
    if (localStorage.getItem("motion")) {
      this.motion = localStorage.getItem('motion') as any;
    }
    return this.motion;
  }
  setMotion(motion: string, store: boolean = true): void {
    this.motion = motion;
    if (store) {
      localStorage.setItem('motion', motion);
    }
    switch(motion) {
      case 'off': {
        this.renderer.addClass(this.document.body, 'motion-off');
        break;
      }
      default: {
        this.renderer.removeClass(this.document.body, 'motion-off');
        break;
      }
    }
  }

  getTypo(): string {
    if (localStorage.getItem("typo")) {
      this.typo = localStorage.getItem('typo') as any;
    }
    return this.typo;
  }
  setTypo(typo: string, store: boolean = true): void {
    this.typo = typo;
    if (store) {
      localStorage.setItem('typo', typo);
    }
    switch(typo) {
      case 'medium': {
        this.renderer.addClass(this.document.body, 'typo-medium');
        this.renderer.removeClass(this.document.body, 'typo-large');
        break;
      }
      case 'large': {
        this.renderer.addClass(this.document.body, 'typo-large');
        this.renderer.removeClass(this.document.body, 'typo-medium');
        break;
      }
      default: {
        this.renderer.removeClass(this.document.body, 'typo-medium');
        this.renderer.removeClass(this.document.body, 'typo-large');
        break;
      }
    }
  }
}

import { Component, OnInit } from '@angular/core';
import {SeoService} from "../../services/seo.service";

@Component({
  selector: 'app-datenschutz',
  templateUrl: './datenschutz.component.html',
  styleUrls: ['./datenschutz.component.scss']
})
export class DatenschutzComponent implements OnInit {

  constructor(private seoService: SeoService) { }

  ngOnInit(): void {
    this.seoService.updateTitle('Datenschutz');
    this.seoService.updateDescription('Datenschutzerklärung, DSGVO');
    this.seoService.updateKeywords('Datenschutzerklärung der IDOGO GmbH ✓ Wir nehmen den Schutz Ihrer Daten ernst ✓');
  }

}

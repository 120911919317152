import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(private title: Title, private meta: Meta) { }

  updateTitle(title: string): void {
    this.title.setTitle(title);
  }

  updateKeywords(keywords: string): void {
    this.meta.updateTag({ name: 'keywords', content: keywords })
  }

  updateDescription(desc: string): void {
    this.meta.updateTag({ name: 'description', content: desc })
  }
}

import {Component, OnInit} from '@angular/core';
import {StyleService} from "./services/style.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  year = 0;
  displaySubNav = false;

  constructor(
    public styleService: StyleService
  ) {}

  toggleSubNav(): void {
    this.displaySubNav = !this.displaySubNav;
  }

  clickRouterLink(): void {
    this.styleService.view = 'main';
  }

  ngOnInit(): void {
    this.styleService.initSettings('light', 'on', 'small');
    this.year = (new Date()).getFullYear();
    this.styleService.view = 'main';
  }
}

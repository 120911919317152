import { Component, OnInit } from '@angular/core';
import {SeoService} from "../../services/seo.service";

@Component({
  selector: 'app-impressum',
  templateUrl: './impressum.component.html',
  styleUrls: ['./impressum.component.scss']
})
export class ImpressumComponent implements OnInit {

  constructor(private seoService: SeoService) { }

  ngOnInit(): void {
    this.seoService.updateTitle('Impressum');
    this.seoService.updateDescription('Datenschutzerklärung, DSGVO');
    this.seoService.updateKeywords('Impressum der IDOGO GmbH ✓');
  }

}
